export class User{
  public uid: string = '';
  public email: string = '';
  public phone: IonIntlTelInputModel;
  public firstName: string = '';
  public lastName: string = '';
  public current_name: string = '';
  public photo: string = '';
  public username: string = '';
  public emailVerified: boolean = false;
  public created_at: Date = new Date();
  public is_enabled: boolean = true;
  public favorites: Array<Favorites_User> = [];
  public user_active: boolean = true;
  public method_payment: string = '';

  public image: string = '';
  public notifications: boolean = false;
  public is_admin: boolean = false;
  public is_client: boolean = false;
  public is_super: boolean = false;
  public pushToken: string = '';
  public country: string = '';
  public deparment: string = '';
  public municipality: string = '';
  public zone: string = '';
  public direction: Array<Direction_User> = [];
  public who_receives: string = '';
  public sucursal: string = '';
  public sucursal_uid: string = '';
  public updated_at: Date = null;
  public deleted_at: Date = null;
  public register_number: string = '';
  public last_zone: string = '';
  public verification_code: string = '';
  public verificate_phone: boolean = false;
  public verification_omit: boolean = false;
  public skip_counter: number = 10;

  constructor(){}
}

export class Direction_User{
  public direction: string = '';
  public is_default: boolean = false;
  public lat_long: Coordinates_User;
  public name_place: string = '';
  public timestamp: Date = null;
  public type: string = '';
}

export class Coordinates_User{
  public _lat: number = 0;
  public _long: number = 0;
}

export class Favorites_User{
  public uid_comerce: string = '';
  public uid_category: string = '';
  public product: string = '';
  public timestamp: Date = null;
  public like: boolean = false;
}

export class IonIntlTelInputModel {
  internationalNumber: string = '';
  nationalNumber: string = '';
  isoCode: string = '';
  dialCode: string = '';
}
