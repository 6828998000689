import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "./services/auth.service";
import {HttpService} from "./services/http.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Admin';
  private url: string;

  constructor(
    private router: Router,
    private auth: AuthService,
    private service: HttpService,
  ) {
  }

  ngOnInit(): void {

  }

}
