import {Component, OnInit, ViewChild, ElementRef, Inject, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from "../../../services/auth.service";
import Swal from 'sweetalert2';
import {HttpService} from "../../../services/http.service";
import {environment} from "../../../../environments/environment";
import {User} from "../../../shared/user";
import {RolesService} from "../../../services/roles.service";
import {CommonService} from "../../../services/common.service";
import {EventsService} from "../../../services/events.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  newOrders: any[] = [];
  currency: string = environment.currency;
  public user: User;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private auth: AuthService,
    private service: HttpService,
    private rolesService: RolesService,
    private commonService: CommonService,
    private events: EventsService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.rolesService.verifyRoles().then(value => {
        let url    = this.router.url;
        let title  = url.substring(url.indexOf('/') + 1);
        let page   = title.split('/');
        console.log('value from navbar promise', value)
        if (!value) this.commonService.alertPermissionDenied(page[0]);
      });
    });
    this.service.getOrdersByStatus('New').subscribe(res => {
      console.log('getOrdersByStatus', res)
      this.newOrders = res.listing;
    })
    this.auth.getUser2().then(async userLocal => {
      // console.log('userLocal', userLocal)
      this.user = userLocal;
      // console.log('User', this.user)
    }, (e) => {

    });
  }

  ngOnInit(): void {
    this.events.publish('alert:sound', { from: 'header', sound: true });
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    Swal.fire({
      icon: 'info',
      text: 'Logging out, please wait a moment',
      showConfirmButton: false,
      backdrop: 'static',
      allowOutsideClick: false,
    })
    this.auth.logout().then((e) => {
      localStorage.removeItem('isLoggedin');
      this.router.navigate(['/auth/login']).then(() => Swal.close());
    }).catch((e) => console.log(e));
  }


}
