export const environment = {
  production: false,
  currency: '$',

  firebase: {
    apiKey: "AIzaSyACt8BEF8zwh49ErWHvj14ZdKwL-mqlLsE",
    authDomain: "ianos-newrolling-dev.firebaseapp.com",
    projectId: "ianos-newrolling-dev",
    storageBucket: "ianos-newrolling-dev.appspot.com",
    messagingSenderId: "1059308592105",
    appId: "1:1059308592105:web:0aba5eef8bf6f7cd100978",
    measurementId: "G-M90M91QVE7"
  },

  googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',
  urlBase: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net',
  urlBaseWompy: 'https://us-central1-ianos-newrolling-dev.cloudfunctions.net/Wompi',
  urlBaseNR: 'https://api.newrolling.com',
  apikey: 'c68790b2244-50a832abf6f6e-4f4c-606dc078',
  project: "New Rolling",

};
